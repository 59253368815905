import React from 'react';
import { Link } from 'react-router-dom';

interface ContactInfo {
  instagram: string;
  gmail: string;
  telefone: string;
}

interface TeamMemberProps {
  name: string;
  role: string;
  description: string;
  contact: ContactInfo;
  backgroundColor: string;
  image: string;
  imagePosition: 'left' | 'right';
  pageUrl: string; // Adicionando a propriedade pageUrl
}

const TeamMember: React.FC<TeamMemberProps> = ({
  name,
  role,
  description,
  contact,
  backgroundColor,
  image,
  imagePosition,
  pageUrl, // Usando a nova propriedade
}) => {
  return (
    <div
      className={`flex w-full bg-gray-800 p-6 my-6 md:p-10 shadow-lg shadow-black text-left flex-col ${
        imagePosition === 'right' ? 'md:flex-row-reverse' : 'md:flex-row'
      } items-center md:justify-start md:mx-0`}
      style={{ backgroundColor }}
    >
      {/* Imagem */}
      <div className={`w-full md:w-1/3 flex flex-shrink-0 mb-4 md:mb-0 md:mx-4 ${imagePosition === 'left' ? 'mx-0 md:ml-4' : 'mx-0 md:mr-4'}`}>
        <img
          src={image}
          alt={name}
          className="w-full h-auto object-cover rounded-lg"
          onDragStart={(e) => e.preventDefault()}
        />
      </div>

      {/* Texto */}
      <div className="w-full md:w-2/3 flex flex-col items-start">
        <h2 className="font-bilbo text-3xl md:text-5xl text-white">{name}</h2>
        <div className="border-t border-white w-1/3 my-2"></div>
        <p className="font-bilbo text-xl md:text-4xl mt-2 text-white">{role}</p>
        <p className="font-halant text-base md:text-lg mt-2 text-white">{description}</p>
        <div className="mt-4 flex flex-col md:flex-row gap-4 md:gap-8">
          <a href={`https://instagram.com/${contact.instagram}`} className="bi bi-instagram text-xs md:text-sm text-gray-300"> {contact.instagram}</a>
          <a href={`mailto:${contact.gmail}`} className="bi bi-envelope text-xs md:text-sm text-gray-300"> {contact.gmail}</a>
          <a href={`tel:${contact.telefone}`} className="bi bi-telephone text-xs md:text-sm text-gray-300"> {contact.telefone}</a>
        </div>
        <Link to={pageUrl}> {/* Alterando aqui para usar pageUrl */}
          <button className="mt-6 px-6 py-2 w-32 bg-transparent border-2 border-white text-white rounded-full duration-300 hover:scale-105 hover:bg-violet-950">
            Ver mais
          </button>
        </Link>
      </div>
    </div>
  );
};

export default TeamMember;
