import React from 'react';

interface ServiceCardProps {
  title: string;
  description: string;
  image: string;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ title, description, image }) => {
  return (
    <div className="bg-gray-800 p-6 rounded-lg shadow-lg shadow-black text-center w-full max-w-xs mx-4 mb-8 flex flex-col items-center relative"> {/* Adicionado relative aqui */}
      <div className="absolute top-[-50px]"> {/* Ajuste a posição aqui conforme necessário */}
        <img 
          src={image} 
          alt={title} 
          className="w-32 h-32 object-cover mb-4" 
        />
      </div>
      <div className="mt-20"> {/* Adicione margens para compensar a imagem movida para cima */}
        <h3 className="text-xl font-semibold text-white">{title}</h3>
        <p className="py-4 text-gray-400">{description}</p>
      </div>
    </div>
  );
};

export default ServiceCard;