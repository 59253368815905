import React from 'react';

const ReembolsoContent: React.FC = () => (
  <div>
    <h1>Política de Reembolso e Cancelamento</h1>
    <h2>- Introdução</h2>
    <p>
      Na DHeroCorp, nos esforçamos para garantir a satisfação total dos nossos clientes com nossos serviços de design gráfico, 
      design digital e edição de fotos. No entanto, entendemos que podem surgir situações em que um reembolso ou cancelamento 
      seja necessário. Esta política descreve os termos e condições para reembolsos e cancelamentos.
    </p>

    <h2>- Solicitação de Cancelamento</h2>
    <p>
      Os clientes podem solicitar o cancelamento de um serviço contratado nas seguintes condições:
      <ul>
        <li><strong>Antes do Início do Serviço:</strong> Cancelamentos solicitados antes do início do serviço contratado serão elegíveis para um reembolso total.</li>
        <li><strong>Após o Início do Serviço:</strong> Cancelamentos solicitados após o início do serviço serão avaliados caso a caso. Dependendo do progresso do trabalho, um reembolso parcial pode ser concedido.</li>
      </ul>
    </p>

    <h2>- Solicitação de Reembolso</h2>
    <p>
      Os clientes podem solicitar um reembolso nas seguintes condições:
      <ul>
        <li><strong>Serviço Não Concluído:</strong> Se o serviço não foi concluído conforme acordado, o cliente pode solicitar um reembolso total ou parcial, dependendo do estágio de conclusão.</li>
        <li><strong>Insatisfação com o Serviço:</strong> Se o cliente não estiver satisfeito com o serviço prestado, ele deve entrar em contato conosco dentro de 14 dias após a entrega para discutir possíveis soluções, incluindo ajustes ou reembolsos parciais.</li>
      </ul>
    </p>

    <h2>- Processo de Solicitação</h2>
    <p>
      Para solicitar um cancelamento ou reembolso, os clientes devem seguir os seguintes passos:
      <ul>
        <li>Enviar um e-mail para <a className='text-blue-700 hover:text-blue-950' href="mailto:suporte@dherocorp.com">suporte@dherocorp.com</a> com o assunto “Solicitação de Cancelamento/Reembolso”.</li>
        <li>Incluir no e-mail o nome completo, detalhes do serviço contratado, motivo do cancelamento/reembolso e qualquer documentação relevante.</li>
      </ul>
    </p>

    <h2>- Avaliação e Resposta</h2>
    <p>
      Após receber a solicitação, nossa equipe avaliará o pedido e responderá dentro de 7 dias úteis. 
      Se aprovado, o reembolso será processado e creditado na forma de pagamento original dentro de 14 dias úteis.
    </p>

    <h2>- Exceções</h2>
    <p>
      Não serão concedidos reembolsos nas seguintes situações:
      <ul>
        <li>Serviços concluídos e entregues conforme acordado.</li>
        <li>Cancelamentos solicitados após o prazo de 14 dias da entrega do serviço.</li>
      </ul>
    </p>

    <h2>- Alterações na Política</h2>
    <p>
      Reservamo-nos o direito de alterar esta política a qualquer momento. Quaisquer alterações serão comunicadas 
      aos clientes por e-mail.
    </p>

    <h2>- Contato</h2>
    <p>
      Para mais informações ou dúvidas sobre nossa política de reembolso e cancelamento, entre em contato conosco 
      pelo e-mail <a className='text-blue-700 hover:text-blue-950' href="mailto:suporte@dherocorp.com">suporte@dherocorp.com</a>.
    </p>
  </div>
);

export default ReembolsoContent;