import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';

// Configurações do Firebase
const firebaseConfig = {
  apiKey: "AIzaSyAq7pZ0o6jx2fy6vjtPlYPCNqWG-D-MQ_k",
  authDomain: "portfolio-96c72.firebaseapp.com",
  projectId: "portfolio-96c72",
  storageBucket: "portfolio-96c72.appspot.com",
  messagingSenderId: "843370548471",
  appId: "1:843370548471:web:7405e71f9a2d0071c397f2",
  measurementId: "G-1BY2LNEV21"
};

const app = initializeApp(firebaseConfig);
// Inicializa o Storage
const storage = getStorage(app);

// Inicializa o Firestore (opcional, se você usar)
const firestore = getFirestore(app);

// Exporta o que é necessário
export { app, storage, firestore };