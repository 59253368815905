import React, { useEffect, useState } from 'react';
import { ref, listAll, getDownloadURL, getMetadata } from 'firebase/storage';
import { storage } from '../firebaseConfig';
import SkillsCards from './SkillsCards';

interface SkillsCardsProps {
  title: string;
  nivel: string;
  image: string;
  content: string;
}

interface SkillsCardsContainerProps {
  folder: string; // Adiciona a nova prop
}

const SkillsCardsContainer: React.FC<SkillsCardsContainerProps> = ({ folder }) => {
  const [skills, setSkills] = useState<SkillsCardsProps[]>([]);

  useEffect(() => {
    const fetchSkillsData = async () => {
      try {
        // Use a prop folder para criar a referência da pasta
        const iconsRef = ref(storage, `icons/${folder}/`);

        // Listar todos os arquivos dentro da pasta
        const iconsList = await listAll(iconsRef);

        const skillsData: SkillsCardsProps[] = [];

        for (const item of iconsList.items) {
          const imageUrl = await getDownloadURL(item);
          const metadata = await getMetadata(item);

          const title = item.name.split('.').slice(0, -1).join('.');

          skillsData.push({
            title: title,
            nivel: metadata.customMetadata?.nivel || 'Nível padrão',
            image: imageUrl,
            content: metadata.customMetadata?.content || 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
          });
        }

        setSkills(skillsData);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    };

    fetchSkillsData();
  }, [folder]); // Adiciona folder como dependência

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mx-5 md:mx-20">
      {skills.map((skill, index) => (
        <SkillsCards
          key={index}
          title={skill.title}
          nivel={skill.nivel}
          image={skill.image}
          content={skill.content}
        />
      ))}
    </div>
  );
};

export default SkillsCardsContainer;