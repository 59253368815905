import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/ServicesStyles.css';
import Footer from '../components/Headers/Footer';
import DesignDigital from '../components/Services/DesignDigital';
import EdicaoFotos from '../components/Services/EdiçãoDeFotos';
import DesignGrafico from '../components/Services/DesignGrafico';
import DesenvolvimentoSites from '../components/Services/DesenvolvimentoDeSites';

const Services: React.FC = () => {
  const navigate = useNavigate();
  const [selectedSection, setSelectedSection] = useState('GraphicDesign');

  // UseEffect para verificar o hash da URL
  useEffect(() => {
    window.scrollTo(0, 0);

    const hash = window.location.hash;
    if (hash) {
      const section = hash.replace('#', '');
      if (['GraphicDesign', 'DesignDigital', 'EdicaoFotos', 'DesenvolvimentoSites'].includes(section)) {
        setSelectedSection(section);
      }
    }
  }, []);

  const renderContent = () => {
    switch (selectedSection) {
      case 'GraphicDesign':
        return <DesignGrafico />;
      case 'DesignDigital':
        return <DesignDigital />;
      case 'EdicaoFotos':
        return <EdicaoFotos />;
      case 'DesenvolvimentoSites':
        return <DesenvolvimentoSites />;
      default:
        return null;
    }
  };

  return (
    <div className="App">
      <button 
        className="return-button mb-4 md:mb-0" // Adicionando margem inferior para mobile
        onClick={() => navigate('/')}
      >
        <i className="bi bi-arrow-left"></i>
      </button>
      <main className="flex flex-col items-center min-h-screen">
        <div className="term-section max-w-screen-lg w-full p-4">
          <h1 className='text-white text-3xl text-center mt-4'>Serviços</h1> {/* Adicionando margem superior aqui */}
          <section className='w-full bg-white rounded-lg p-4'>
            <nav className='flex flex-col md:flex-row gap-4 md:gap-10 mb-6'>
              <ul 
                className={`cursor-pointer nav-item ${selectedSection === 'GraphicDesign' ? 'selected' : ''}`} 
                onClick={() => setSelectedSection('GraphicDesign')}
              >
                Design Gráfico
              </ul>
              <ul 
                className={`cursor-pointer nav-item ${selectedSection === 'DesignDigital' ? 'selected' : ''}`} 
                onClick={() => setSelectedSection('DesignDigital')}
              >
                Design Digital
              </ul>
              <ul 
                className={`cursor-pointer nav-item ${selectedSection === 'EdicaoFotos' ? 'selected' : ''}`} 
                onClick={() => setSelectedSection('EdicaoFotos')}
              >
                Edição de Fotos
              </ul>
              <ul 
                className={`cursor-pointer nav-item ${selectedSection === 'DesenvolvimentoSites' ? 'selected' : ''}`} 
                onClick={() => setSelectedSection('DesenvolvimentoSites')}
              >
                Desenvolvimento de Sites
              </ul>
            </nav>
            <div className='section-content mx-4'>
              {renderContent()}
            </div>
          </section>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Services;
