import React from 'react';
import { Link } from 'react-router-dom';

const MainHeader: React.FC = () => {
  return (
    <header
      className="relative h-screen flex flex-col justify-center text-left md:text-left bg-cover bg-center"
      style={{
        backgroundImage:
          'url("https://i.pinimg.com/564x/04/b5/c6/04b5c6097eb114183151ec6efd53764d.jpg")',
      }}
    >
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="relative ml-0 md:ml-32 z-10 text-white p-4 flex flex-col items-center md:items-start">
        <h1 className="font-halant text-6xl md:text-5xl font-bold mt-10 md:mt-5">DHeroCorp</h1>
        <p className="font-halant text-xl md:text-xl font-normal mt-5 md:mt-3 text-center md:text-left w-full md:w-auto" style={{ textAlign: 'justify' }}>
          Seja criando identidades visuais, desenvolvendo materiais gráficos envolventes ou aprimorando imagens com precisão.
        </p>
        <Link to="/gallery" className="mt-5 md:mt-3">
          <button className="font-halant font-semibold text-lg md:text-xl w-48 md:w-40 px-4 py-2 bg-purple-600 text-white rounded-lg border-2 border-transparent duration-300 hover:bg-transparent hover:border-white hover:scale-105">
            Galeria
          </button>
        </Link>
      </div>
    </header>
  );
};

export default MainHeader;