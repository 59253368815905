import React from 'react';
import { storage } from '../../firebaseConfig';
import { ref, getDownloadURL } from 'firebase/storage';
import { Link } from 'react-router-dom'; // Importe Link se estiver usando React Router

interface ContactInfo {
  instagram: string;
  gmail: string;
  telefone: string;
}

interface HeaderPortProps {
  name: string;
  role: string;
  description: string;
  contact: ContactInfo;
  backgroundColor: string;
  buttonColor: string; // Para a cor do botão
  image?: string; // Prop para a imagem fixa
}

const HeaderPort: React.FC<HeaderPortProps> = ({
  name,
  role,
  description,
  contact,
  backgroundColor,
  buttonColor,
  image,
}) => {
  const [logoUrl, setLogoUrl] = React.useState('');

  // Função para obter a URL da imagem do logo
  const getLogoUrl = async () => {
    const logoRef = ref(storage, 'Site/Logo.png');
    const url = await getDownloadURL(logoRef);
    setLogoUrl(url);
  };

  React.useEffect(() => {
    getLogoUrl();
  }, []);

  // Função para abrir o e-mail
  const handleContactClick = () => {
    window.location.href = `mailto:${contact.gmail}`;
  };

  return (
    <div>
      <header
        className="w-full h-screen flex items-center justify-center relative"
        style={{ backgroundColor }}
      >
        {/* Sombra no topo */}
        <div className="absolute inset-x-0 top-0 h-1/2 bg-gradient-to-b from-black to-transparent pointer-events-none"></div>

        {/* Logo */}
        {logoUrl && (
          <Link to="/" className="absolute top-4 left-5">
            <img
              src={logoUrl}
              alt="Logo"
              className="h-16" // Ajuste o tamanho conforme necessário
              onDragStart={(e) => e.preventDefault()}
            />
          </Link>
        )}

        {/* Conteúdo do Header */}
        <div className="relative z-10 flex flex-col items-center text-center w-full max-w-xl">
          <h1 className="font-bilbo text-4xl md:text-6xl text-white">{name}</h1>
          <div className="border-t border-white w-1/4 my-2"></div>
          <p className="font-bilbo text-2xl md:text-3xl text-white">{role}</p>
          <p className="font-bilbo text-lg md:text-xl text-white mt-4 px-10">{description}</p>

          {/* Contatos lado a lado com ícones do Bootstrap */}
          <div className="mt-6 flex gap-4 justify-center items-center flex-wrap">
            <a href={`https://instagram.com/${contact.instagram}`} target="_blank" rel="noopener noreferrer" className="flex items-center text-gray-300">
              <i className="bi bi-instagram mr-2"></i>
              {contact.instagram}
            </a>
            <a href={`mailto:${contact.gmail}`} className="flex items-center text-gray-300">
              <i className="bi bi-envelope mr-2"></i>
              {contact.gmail}
            </a>
            <a href={`tel:${contact.telefone}`} className="flex items-center text-gray-300">
              <i className="bi bi-telephone mr-2"></i>
              {contact.telefone}
            </a>
          </div>

          {/* Botão de contato */}
          <button 
            onClick={handleContactClick} // Adicionando o manipulador de clique
            className='w-2/5 h-10 m-5 shadow-md shadow-black rounded-full text-white font-medium hover:bg-red-950 transition-all duration-500' 
            style={{ backgroundColor: buttonColor }}>
            Entrar em Contato
          </button>
        </div>
      </header>
      <div className='w-full h-16 bg-black'></div>
    </div>
  );
};

export default HeaderPort;