import React from 'react';
import { Outlet } from 'react-router-dom';
import Maintenance from './views/Maintenance';

const App: React.FC = () => {
  const isMaintenance = process.env.REACT_APP_MAINTENANCE_MODE === 'true';

  if (isMaintenance) {
    return <Maintenance />;

  }

  return (
    <>
      <Outlet />
    </>
  );
};

export default App;