import React, { useState } from 'react';

interface ProjectsCardsProps {
  title: string;
  description: string;
  image: string;
  content?: string;
}

const ProjectsCards: React.FC<ProjectsCardsProps> = ({ title, description, image, content }) => {

  return (
    <div 
      className="border-black h-96 flex items-end gap-4 cursor-pointer" 
      style={{ 
        borderRadius: '8px',
        backgroundImage: `url(${image})`, 
        backgroundSize: 'cover', 
        backgroundPosition: 'center',
      }}>
      <div className="bg-gray-700 p-4 rounded w-full h-2/4 overflow-hidden transition-all duration-500 hover:shadow-lg hover:shadow-black">
        <div className='text-white'>
          <h2 className="text-xl font-bold">{title}</h2>
          <p className="bg-transparent font-semibold">
            {description || 'Descrição'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProjectsCards;
