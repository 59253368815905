import React, { useState } from 'react';

interface SkillsCardsProps {
  title: string;
  nivel: string; // Alterado de description para nivel
  image: string;
  content?: string;
}

const SkillsCards: React.FC<SkillsCardsProps> = ({ title, nivel, image, content }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      className="border-black p-4 flex items-center gap-4 bg-gray-700 cursor-pointer hover:shadow-md hover:shadow-sky-400 transition-all duration-500"
      onClick={toggleExpand}
      style={{ borderRadius: '8px' }}
    >
      <img src={image} alt={title} className="w-16 h-16" />
      <div className="flex flex-grow">
        <div className='text-white'>
          <h2 className="text-xl font-bold">{title}</h2>

          <div
            className={`transition-all duration-700 ease-in-out overflow-hidden ${
              isExpanded ? 'max-h-40' : 'max-h-0'
            }`}
          >
            <p className="mt-4 bg-transparent font-semibold">
              {content || 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkillsCards;