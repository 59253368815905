import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-800 text-white py-10">
      <div className="container mx-auto">
        <div className="flex flex-wrap justify-between">
          <div className="footer-col w-full sm:w-1/4 px-4 mb-6 text-center"> {/* Adicionada a classe text-center */}
            <h4 className="text-lg font-semibold mb-4">Serviços</h4>
            <ul className="space-y-2">
            <li className="transform transition-transform duration-200 hover:translate-x-1">
                <Link to="/" className="hover:text-gray-300">Home</Link>
              </li>
              <li className="transform transition-transform duration-200 hover:translate-x-1">
                <Link to="/services#GraphicDesign" className="hover:text-gray-300">Design Gráfico</Link>
              </li>
              <li className="transform transition-transform duration-200 hover:translate-x-1">
                <Link to="/services#DesignDigital" className="hover:text-gray-300">Design Digital</Link>
              </li>
              <li className="transform transition-transform duration-200 hover:translate-x-1">
                <Link to="/services#EdicaoFotos" className="hover:text-gray-300">Edição de Fotos</Link>
              </li>
              <li className="transform transition-transform duration-200 hover:translate-x-1">
                <Link to="/services#DesenvolvimentoSites" className="hover:text-gray-300">Desenvolvimento De Sites</Link>
              </li>
            </ul>
          </div>

          <div className="footer-col w-full sm:w-1/4 px-4 mb-6 text-center"> {/* Adicionada a classe text-center */}
            <h4 className="text-lg font-semibold mb-4">Termos Gerais</h4>
            <ul className="space-y-2">
              <li className="transform transition-transform duration-200 hover:translate-x-1">
                <Link to="/termos#FAQ" className="hover:text-gray-300">FAQ</Link>
              </li>
              <li className="transform transition-transform duration-200 hover:translate-x-1">
                <Link to="/termos#Privacidade" className="hover:text-gray-300">Privacidade</Link>
              </li>
              <li className="transform transition-transform duration-200 hover:translate-x-1">
                <Link to="/termos#Reembolso" className="hover:text-gray-300">Reembolso e Cancelamento</Link>
              </li>
              <li className="transform transition-transform duration-200 hover:translate-x-1">
                <Link to="/termos#PropriedadeIntelectual" className="hover:text-gray-300">Propriedade Intelectual</Link>
              </li>
            </ul>
          </div>

          <div className="footer-col w-full sm:w-1/4 px-4 mb-6 text-center"> {/* Adicionada a classe text-center */}
            <h4 className="text-lg font-semibold mb-4">Contato</h4>
            <ul className="space-y-2">
              <li>
                contato@dherocorp.com
              </li>
              <li className="transform transition-transform duration-200 hover:translate-x-1">
                <Link className='hover:text-gray-300' to="https://wa.me//54981172243" target='_blank'>(54) 98117-2243</Link>
              </li>
            </ul>
          </div>

          <div className="footer-col w-full sm:w-1/4 px-4 mb-6 text-center"> {/* Adicionada a classe text-center */}
            <h4 className="text-lg font-semibold mb-4">Nos Acompanhe</h4>
            <div className="flex justify-center space-x-4"> {/* Adicionado justify-center para centralizar os ícones */}
              <Link to="https://discord.com/servers/center-cafe-1061343817778860173" target='_blank' className="text-white hover:text-gray-300"><i className="bi bi-discord"></i></Link>
              <Link to="https://www.instagram.com/dherocorp_of/" target='_blank' className="text-white hover:text-gray-300"><i className="bi bi-instagram"></i></Link>
            </div>
          </div>
        </div>
      </div>
      
      <div className="text-center mt-8 text-sm">
        <p>&copy; {new Date().getFullYear()} DHeroCorp. Todos os direitos reservados.</p>
      </div>
    </footer>
  );
};

export default Footer;
