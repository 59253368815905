import React from 'react';

const FAQ: React.FC = () => {
  return (
    <div>
      <h1 className="text-lg font-bold mb-4">FAQ - Perguntas Frequentes</h1>
      
      <h2>- Quais serviços a DHeroCorp oferece?</h2>
      <p>A DHeroCorp oferece serviços de design gráfico, design digital e edição de fotos.</p>

      <h2>- Como posso entrar em contato ou solicitar um serviço?</h2>
      <p>Você pode solicitar um serviço entrando em contato conosco através do nosso e-mail <a className='text-blue-700 hover:text-blue-950' href="mailto:suporte@dherocorp.com">suporte@dherocorp.com</a> ou pelo nosso contato no WhatsApp (a ser configurado).</p>

      <h2>- Quais informações são necessárias para iniciar um projeto?</h2>
      <p>Para iniciar um projeto, precisamos de informações detalhadas sobre suas necessidades e expectativas. Isso inclui uma descrição do projeto, referências visuais (se houver), prazos e qualquer outra informação relevante que possa nos ajudar a entender melhor suas necessidades.</p>

      <h2>- Qual é o prazo de entrega dos serviços?</h2>
      <p>O prazo de entrega varia dependendo da complexidade e do escopo do projeto. Após a análise inicial, forneceremos um cronograma estimado para a conclusão do trabalho.</p>

      <h2>- Como funciona o processo de pagamento?</h2>
      <p>O pagamento pode ser realizado através de transferência bancária ou outros métodos acordados previamente. Normalmente, solicitamos um adiantamento de 50% do valor total do projeto antes de iniciar o trabalho, com o saldo restante a ser pago na entrega final.</p>

      <h2>- A DHeroCorp oferece reembolsos?</h2>
      <p>Sim, oferecemos reembolsos conforme nossa Política de Reembolso e Cancelamento. Se você não estiver satisfeito com o serviço prestado, entre em contato conosco dentro de 14 dias após a entrega para discutir possíveis soluções, incluindo ajustes ou reembolsos parciais.</p>

      <h2>- Quem detém os direitos autorais dos designs criados?</h2>
      <p>Os direitos de uso dos designs criados são transferidos ao cliente, mas a DHeroCorp mantém os direitos morais sobre a obra. Isso inclui o direito de ser reconhecido como o autor do trabalho e a proibição de plágio ou modificações que possam prejudicar a integridade da obra.</p>

      <h2>- Como a DHeroCorp protege meus dados pessoais?</h2>
      <p>Levamos a privacidade dos nossos clientes muito a sério. Todos os dados pessoais são armazenados de forma segura e protegidos por criptografia. Para mais detalhes, consulte nossa Política de Privacidade.</p>

      <h2>- A DHeroCorp utiliza cookies em seu site?</h2>
      <p>Atualmente, não utilizamos cookies ou outras tecnologias de rastreamento em nosso site. Se isso mudar, informaremos nossos usuários e atualizaremos nossa Política de Cookies.</p>
    </div>
  );
};

export default FAQ;