import React from 'react';

const NotFound: React.FC = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-800 text-white">
      <div className="text-center">
        <h1 className="text-4xl font-bold">404 - Página Não Encontrada</h1>
        <p className="mt-4">Desculpe, mas a página que você está procurando não existe.</p>
        <a href="/" className="mt-6 inline-block px-4 py-2 bg-blue-600 rounded hover:bg-blue-500">
          Voltar para a Home
        </a>
      </div>
    </div>
  );
};

export default NotFound;