import React, { useState, useEffect } from 'react';
import arielImage from '../../assets/woman.png';
import HeaderPort from '../../components/Headers/HeaderPort';
import SkillsCardsContainer from '../../components/SkillsCardsContainer';
import TestimonialSlider from '../../components/Testemunhal';
import ProjectsCards from '../../components/ProjectCards';
import { ref, getDownloadURL } from 'firebase/storage';
import { storage } from '../../firebaseConfig';
import Footer from '../../components/Headers/Footer';

const ArielPage: React.FC = () => {
  const [imageUrls, setImageUrls] = useState<{ [key: string]: string | null }>({});

  // Função para buscar imagens do Firebase
  const fetchImage = async (imagePath: string) => {
    try {
      const imageRef = ref(storage, imagePath);
      const url = await getDownloadURL(imageRef);
      return url;
    } catch (error) {
      console.error('Erro ao obter a imagem:', error);
      return null;
    }
  };

  // useEffect para buscar as imagens ao montar o componente
  useEffect(() => {
    const fetchImages = async () => {
      const fundo2Url = await fetchImage('images/Titulo.jpg');
      const fundo3Url = await fetchImage('images/CenterCafé.jpg');
      
      // Atualiza o estado com as URLs das imagens
      setImageUrls({
        fundo2: fundo2Url,
        fundo3: fundo3Url,
      });
    };

    fetchImages();

    // Faz a página começar do topo
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="">
      {/* Aqui fica o Header */}
      <HeaderPort
        name="Ariel"
        role="Editora de Fotos e Designer Digital"
        description="Sou Ariel, uma designer que aprecia todas as formas de arte. Estou constantemente buscando aprimorar meus conhecimentos em design digital e edição de fotos."
        contact={{ 
          instagram: "@dherocorp_of", 
          gmail: "ariel@dherocorp.com", 
          telefone: "+55 (54) 98117-2243" 
        }}
        backgroundColor='#26030A'
        buttonColor='#841212'
      />

      {/* Aqui fica a Biografia */}
      <section className='h-full w-full p-5 md:p-20'>
        <div className='flex flex-col md:flex-row items-center justify-center'>
          <div className='flex flex-col h-full w-full text-center md:text-left md:w-1/2 text-white items-center md:items-start gap-10'>
            <h1 className='text-5xl'>Biografia</h1>
            <p>
              Olá, meu nome é Ariel, sou designer e editora de fotos, apaixonada por transformar ideias em visuais únicos que refletem a essência das pessoas e suas marcas. Minha jornada começou no design, onde aprendi a combinar estética e funcionalidade. Atualmente, busco explorar novas técnicas e estilos, unindo arte e tecnologia para entregar soluções visuais impactantes.<br /><br />
              Adoro explorar a criatividade e me manter atualizada sobre as tendências do design. Minhas ferramentas favoritas incluem Photoshop, Illustrator e Lightroom. Estou sempre em busca de novas inspirações e desafios, planejando expandir meus conhecimentos em animação e design de interface.<br /><br />
              Acredito que a arte é uma forma poderosa de comunicação, e busco sempre transmitir uma mensagem significativa em meu trabalho.
            </p>
          </div>
          <div className='flex w-full md:w-1/2 items-center justify-center'>
            <div className='flex items-center justify-center bg-white w-80 h-80 relative z-10 mt-10 md:mt-0'>
              <img 
                src={arielImage}
                alt="Ariel" 
                className="w-full h-full object-cover absolute top-0 left-0 z-10"
              />
              <div className='relative left-10 bottom-10 items-center justify-center bg-transparent border-white border-4 md:w-80 md:h-80 z-0'>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Aqui fica a Frase */}
      <section className='h-full w-full py-20'>
        <div className='flex w-full h-14 md:h-10 bg-gray-700 justify-center items-center text-center text-white font-semibold'>
          <p >"A arte é a expressão da alma através de cores e formas."</p>
        </div>
      </section>

      {/* Aqui fica o skill card */}
      <section>
        <SkillsCardsContainer folder="ariel" />
      </section>

      {/* Aqui fica os Depoimentos */}
      <TestimonialSlider />

      {/* Aqui fica os Projetos */}
      <section className='flex w-full h-full justify-center items-center'>
        <div className='flex flex-col w-8/12 h-2/3 justify-center items-center text-white mt-20 mb-40'>
          <div className='flex flex-col w-full items-center'>
            <h1 className='text-3xl font-semibold mb-4'>Projetos</h1>
            <div className='w-full bg-white h-1'></div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-6 my-10 w-full">
            <ProjectsCards image={imageUrls.fundo2 || ''} title='DHeroCorp' description='A DHeroCorp é um projeto focado em criar soluções inovadoras nas áreas de edição de fotos, design digital, design gráfico e desenvolvimento de sites simples. Através desse projeto, buscamos transformar ideias em resultados visuais de alto impacto, sempre com foco em eficiência e criatividade.' />
            <ProjectsCards image={imageUrls.fundo3 || ''} title='CenterCafé' description='O projeto CenterCafé é uma comunidade diversificada que abrange pessoas de todas as áreas. Com inúmeros canais dedicados a temas como ajuda pessoal, estudos, programação, socialização, jogos e muito mais, o CenterCafé oferece um espaço interativo e colaborativo para troca de ideias e apoio mútuo.' />
          </div> 
        </div>
      </section>

      {/* Aqui fica o Footer */}
      <Footer />
    </div>
  );
};

export default ArielPage;
