import React from 'react';

const ErrorPage: React.FC = () => {
  return (
    <div className="App">
    </div>
  );
};

export default ErrorPage;
