import React, { useEffect, useState } from 'react';
import { ref, getDownloadURL } from 'firebase/storage';
import { storage } from '../firebaseConfig';

const Projects: React.FC = () => {
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  useEffect(() => {
    const fetchImage = async () => {
      const imageRef = ref(storage, 'images/DoutoresPalhaços.png');

      try {
        const url = await getDownloadURL(imageRef);
        setImageUrl(url);
      } catch (error) {
        console.error('Erro ao carregar a imagem do Firebase:', error);
      }
    };

    fetchImage();
  }, []);

  return (
    <div className="w-full flex flex-col items-center p-4 my-20">
      <h1 className="text-white text-5xl text-center">Projetos</h1>
      <div className='w-8/12 bg-white h-0.5 mb-10 mt-5'></div>
      {imageUrl ? (
        <a 
          href="https://doutorespalhacos.com/" 
          target="_blank" 
          rel="noopener noreferrer"
          className="block max-w-xs w-full text-center"
        >
          <div className="bg-white shadow-lg rounded-lg overflow-hidden opacity-85 hover:opacity-100 transition-all duration-300">
            <img src={imageUrl} alt="Doutores Palhaços" className="w-full h-auto" />
          </div>
          <p className="mt-4 text-lg font-semibold text-white">Doutores Palhaços</p>
        </a>
      ) : (
        <p className="text-center text-gray-500">Carregando imagem...</p>
      )}
    </div>
  );
};

export default Projects;