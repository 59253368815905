import React, { useEffect } from 'react';

const TypeBot: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'module';
    script.innerHTML = `
      import Typebot from 'https://cdn.jsdelivr.net/npm/@typebot.io/js@0.3/dist/web.js';

      Typebot.initBubble({
        typebot: "dherocorp-whatsapp",
        theme: {
          button: { backgroundColor: "#0042DA" },
          chatWindow: { backgroundColor: "#fff" },
        },
      });
    `;
    document.body.appendChild(script);
  }, []);

  return (
    <div className="fixed bottom-5 right-10 z-50"></div>
  );
};

export default TypeBot;