import React from 'react';

const DesenvolvimentoSites: React.FC = () => (
  <div className="p-8 bg-gray-100 text-gray-900">
    <h2 className="text-3xl font-bold text-center mb-8">Serviços de Desenvolvimento de Sites</h2>

    {/* Plano Fixo */}
    <section className="mb-12">
      <h3 className="text-2xl font-semibold text-blue-600 mb-4">Plano Fixo</h3>
      <p className="mb-4">
        Ideal para empresas que buscam um site com custo fixo, adaptado ao porte do negócio. Focado em sites de pequeno/médio porte, com personalizações conforme necessidade.
      </p>
      <div>
        <strong>Serviços Incluídos:</strong>
        <ul className="list-disc list-inside ml-4 mb-4 space-y-2">
          <li>Desenvolvimento responsivo</li>
          <li>Design personalizado</li>
          <li>SEO básico</li>
          <li>Suporte técnico durante o desenvolvimento</li>
          <li>Alterações ilimitadas durante o processo</li>
          <li>Otimização de desempenho</li>
          <li>Treinamento para gestão de conteúdo</li>
        </ul>
      </div>
      <div>
        <strong>Orçamento:</strong>
        <ul className="list-disc list-inside ml-4 space-y-2">
          <li><strong>Pequeno Porte:</strong> R$ 1.000 - R$ 5.000 (até 5 páginas)</li>
          <li><strong>Médio Porte:</strong> R$ 5.000 - R$ 20.000 (até 15 páginas)</li>
        </ul>
      </div>
      <p><strong>Prazo de Entrega:</strong> 15 a 60 dias úteis, dependendo do porte.</p>
    </section>

    {/* Plano Mensal */}
    <section className="mb-12">
      <h3 className="text-2xl font-semibold text-blue-600 mb-4">Plano Mensal</h3>
      <p className="mb-4">
        Oferece manutenção contínua e suporte prioritário para sites que precisam de atualizações regulares e evolutivas.
      </p>
      <div>
        <strong>Serviços Incluídos:</strong>
        <ul className="list-disc list-inside ml-4 mb-4 space-y-2">
          <li>Manutenção e desenvolvimento contínuo</li>
          <li>Design personalizado</li>
          <li>Suporte técnico semanal prioritário</li>
          <li>Hospedagem inclusa</li>
          <li>Alterações ilimitadas</li>
          <li>1 manutenção gratuita por mês</li>
          <li>SEO contínuo e ajustes de desempenho</li>
        </ul>
      </div>
      <p><strong>Orçamento:</strong> R$ 200 - R$ 800/mês, conforme necessidades do cliente.</p>
      <p><strong>Prazo de Entrega:</strong> 15 a 60 dias úteis, com atualizações subsequentes.</p>

      {/* Ajuste no Valor Mensal */}
      <div className="mt-6">
        <h4 className="font-semibold text-gray-700">Ajuste no Valor Mensal</h4>
        <ul className="list-disc list-inside ml-4 space-y-2">
          <li><strong>Inclusão de Funcionalidades:</strong> Aumento do valor conforme complexidade.</li>
          <li><strong>Remoção de Funcionalidades:</strong> Redução proporcional.</li>
          <li><strong>Alteração de Funcionalidades:</strong> Sem alteração, salvo mudanças significativas.</li>
        </ul>
      </div>
    </section>

    {/* Opções de Site */}
    <section className="mb-12">
      <h3 className="text-2xl font-semibold text-blue-600 mb-4">Opções de Site</h3>
      <div>
        <strong>Web Site Tradicional:</strong>
        <p className="ml-4">
          Uma opção para presença online simples e funcional. Ideal para sites institucionais, portfólios e blogs.
        </p>
        <ul className="list-disc list-inside ml-8 mb-4 space-y-2">
          <li>Design responsivo</li>
          <li>SEO básico</li>
          <li>Sem necessidade de atualizações frequentes</li>
        </ul>
      </div>
      <div>
        <strong>Progressive Web App (PWA):</strong>
        <p className="ml-4">
          Experiência de app nativo, acessível offline, ideal para fidelizar usuários.
        </p>
        <ul className="list-disc list-inside ml-8 space-y-2">
          <li>Comportamento de app, sem necessidade de instalação</li>
          <li>Acesso offline (Service Workers)</li>
          <li>Notificações push e ícone de instalação</li>
        </ul>
      </div>
    </section>

    {/* Cobrança */}
    <section>
      <h3 className="text-2xl font-semibold text-blue-600 mb-4">Cobrança</h3>
      <div>
        <strong>Plano Fixo:</strong>
        <ul className="list-disc list-inside ml-4 mb-4 space-y-2">
          <li>Conceituação e Design: 30%</li>
          <li>Front End: 30%</li>
          <li>Back End: 30%</li>
          <li>Revisões e Entrega: 10%</li>
        </ul>
      </div>
      <div>
        <strong>Plano Mensal:</strong>
        <ul className="list-disc list-inside ml-4 space-y-2">
          <li>50% após Conceituação e Design</li>
          <li>50% após Entrega</li>
          <li>Mensalidade: Cobrança recorrente conforme contrato</li>
        </ul>
      </div>
    </section>
  </div>
);

export default DesenvolvimentoSites;
