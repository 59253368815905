import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './views/Home';
import Gallery from './views/Gallery';
import ErrorPage from './views/ErrorPage';
import Termos from './views/Termos';
import Services from './views/Services';
import NotFound from './components/NotFound';
import VictorPage from './views/Portfólio/VictorPage';
import ArielPage from './views/Portfólio/ArielPage';
import Admin from './views/Admin';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [ 
      {
        index: true,
        element: <Home />,
      },
      {
        path: "gallery",
        element: <Gallery />,
      },
      {
        path: "termos",
        element: <Termos />,
      },
      {
        path: "services",
        element: <Services />,
      },
      {
        path: "404",
        element: <NotFound />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
      {
        path: "page/victor",
        element: <VictorPage />,
      },
      {
        path: "page/ariel",
        element: <ArielPage />,
      }
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);