import React from 'react';
import MainHeader from '../components/Headers/MainHeader';
import ServiceCard from '../components/ServiceCard';
import TeamMember from '../components/TeamMember';
import ContactForm from '../components/ContactForm';
import Footer from '../components/Headers/Footer';
import { useLocation } from 'react-router-dom';

// Importe as imagens corretamente
import digitalImage from '../assets/digital.png';
import graficoImage from '../assets/grafico.png';
import photoImage from '../assets/photo.png';
import man from '../assets/man.png';
import woman from '../assets/woman.png';
import TypeBot from '../components/TypeBot';
import Projects from '../components/colaborators';

const Home: React.FC = () => {
  const location = useLocation();
  window.scrollTo(0, 0);

  return (
    <div className="App">
      {location.pathname === '/' && <MainHeader />}

      {/* Restante do conteúdo da página */}
      <section className="flex flex-wrap justify-around pt-48 pb-48 bg-transparent">
        <ServiceCard 
          title="Design Gráfico" 
          description="Design gráfico é a prática de criar visuais que comunicam mensagens por meio de elementos como tipografia, cores e imagens." 
          image={graficoImage}
        />
        <ServiceCard 
          title="Design Digital" 
          description="Design digital envolve a criação de interfaces e experiências visuais para plataformas digitais, como sites e aplicativos, focando na usabilidade e interação." 
          image={digitalImage}
        />
        <ServiceCard 
          title="Edição de Fotos" 
          description="Edição de fotos é o processo de modificar e aprimorar imagens para melhorar sua aparência e transmitir uma mensagem específica." 
          image={photoImage}
        />
      </section>

      <section className="relative justify-between w-full">
        <div className="flex flex-col">
          <div className="flex flex-col w-full px-0 md:w-5/6 md:self-end">
            <TeamMember 
              name="Ariel" 
              role="Editora de Fotos e Designer Digital" 
              description='Oi, eu sou a Ariel! Sou apaixonada por edição de fotos e design, e adoro criar imagens que realmente fazem a diferença. Com experiência em ferramentas como Íbis Paint, Photoshop e Illustrator, transformo ideias em arte que destacam marcas, produtos e personagens...' 
              contact={{ instagram: "dherocorp_of", gmail: "ariel@dherocorp.com", telefone: "+55 (54) 98117-2243" }}
              backgroundColor='#38050E' 
              image={woman}
              imagePosition='right'
              pageUrl='page/ariel'
            />
          </div>
          <div className="flex flex-col w-full px-0 md:w-5/6 md:self-start">
            <TeamMember 
              name="Victor" 
              role="Editor de Fotos e Designer Gráfico" 
              description='Sou Victor, um programador júnior com paixão por tecnologia. Estou desenvolvendo a DHeroCorp e buscando inovar no design gráfico e edição de fotos. Meu objetivo? Expandir minha empresa e dominar tecnologias como React, TypeScript e IA...' 
              contact={{ instagram: "dherocorp_of", gmail: "victormts@dherocorp.com", telefone: "+55 (54) 98117-2243" }} 
              backgroundColor='#091746' 
              image={man}
              imagePosition='left'
              pageUrl='page/victor'
            />
          </div>
        </div>
      </section>

      <ContactForm />
      <Projects/>
      <TypeBot />
      <Footer />
    </div>
  );
};

export default Home;
