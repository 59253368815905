import React, { useEffect, useState, useRef } from 'react';
import { ref, listAll, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import { storage } from '../firebaseConfig';
import {
  AiOutlineClose,
  AiOutlineLeft,
  AiOutlineRight,
  AiOutlinePlayCircle,
  AiOutlinePauseCircle,
} from 'react-icons/ai';
import Footer from '../components/Headers/Footer';

interface Image {
  url: string;
  name: string;
}

const Gallery: React.FC = () => {
  const navigate = useNavigate();
  const [images, setImages] = useState<Image[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isAutoplay, setIsAutoplay] = useState<boolean>(false);
  const [isTouching, setIsTouching] = useState<boolean>(false);
  const [fadeNext, setFadeNext] = useState<boolean>(true);
  const autoplayRef = useRef<NodeJS.Timeout | null>(null);
  const touchStartX = useRef<number>(0);
  const touchEndX = useRef<number>(0);

  useEffect(() => {
    const fetchImages = async () => {
      const imagesRef = ref(storage, 'destaque/');
      try {
        const result = await listAll(imagesRef);
        const imageUrls = await Promise.all(
          result.items.map(async (item) => {
            const url = await getDownloadURL(item);
            return { url, name: item.name };
          })
        );
        setImages(imageUrls);
      } catch (error) {
        console.error('Erro ao buscar imagens do Firebase:', error);
      }
    };

    fetchImages();
  }, []);

  useEffect(() => {
    if (isAutoplay && isModalOpen && images.length > 0) {
      autoplayRef.current = setInterval(() => {
        handleNextImage();
      }, 3000);
    } else {
      if (autoplayRef.current) {
        clearInterval(autoplayRef.current);
        autoplayRef.current = null;
      }
    }

    return () => {
      if (autoplayRef.current) {
        clearInterval(autoplayRef.current);
      }
    };
  }, [isAutoplay, isModalOpen, images.length]);

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [isModalOpen]);

  const handleNextImage = () => {
    setFadeNext(false);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      setFadeNext(true);
    }, 500);
  };

  const openModal = (index: number) => {
    setCurrentIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsAutoplay(false);
    if (autoplayRef.current) {
      clearInterval(autoplayRef.current);
      autoplayRef.current = null;
    }
  };

  const showPrev = () => {
    setFadeNext(false);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
      setFadeNext(true);
    }, 500);
  };

  const showNext = () => {
    handleNextImage(); 
  };

  const toggleAutoplay = () => {
    setIsAutoplay((prev) => !prev);
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    touchStartX.current = e.touches[0].clientX;
    setIsTouching(true);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!isTouching) return;
    touchEndX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    const threshold = 50;
    if (touchStartX.current - touchEndX.current > threshold) {
      showNext();
    } else if (touchEndX.current - touchStartX.current > threshold) {
      showPrev();
    }
    setIsTouching(false);
  };

  const previousIndex = (currentIndex - 1 + images.length) % images.length;
  const nextIndex = (currentIndex + 1) % images.length;

  return (
    <div>
      <div className="w-full h-full flex flex-col items-center justify-center p-4">
        <div className='flex flex-row justify-between'>
        {!isModalOpen && (
        <button className="return-button mb-4 md:mb-0 left-0" onClick={() => navigate('/')}>
            <i className="bi bi-arrow-left"></i>
        </button>
        )}
        <h1 className='font-halant text-white text-5xl my-5'>Galeria</h1>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          {images.map((image, index) => (
            <img
              key={image.name}
              src={image.url}
              alt={image.name}
              className="object-cover w-full h-64 rounded-lg cursor-pointer hover:opacity-60 hover:scale-105 duration-500"
              onClick={() => openModal(index)}
            />
          ))}
        </div>

        {isModalOpen && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-95">
            <button
              className="absolute top-4 right-4 text-white text-4xl focus:outline-none z-20"
              onClick={closeModal}
              aria-label="Fechar Galeria"
            >
              <AiOutlineClose />
            </button>
            <div className="relative flex items-center justify-center w-full mx-0">
              {/* Imagens laterais fora da imagem central */}
              <img
                src={images[previousIndex].url}
                alt={images[previousIndex].name}
                className="absolute left-40 w-24 h-24 object-cover rounded-lg opacity-60 hidden md:block"
              />
              <img
                src={images[nextIndex].url}
                alt={images[nextIndex].name}
                className="absolute right-40 w-24 h-24 object-cover rounded-lg opacity-60 hidden md:block"
              />
              <img
                src={images[currentIndex].url}
                alt={images[currentIndex].name}
                className={`w-11/12 md:w-1/2 h-auto max-h-screen object-contain rounded-lg transition-opacity duration-500 ${fadeNext ? 'opacity-100' : 'opacity-0'}`}
                onClick={(e) => e.stopPropagation()}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
              />
            </div>
            <button
              className="absolute bottom-4 right-4 text-white text-4xl focus:outline-none flex items-center space-x-2 z-20"
              onClick={toggleAutoplay}
              aria-label={isAutoplay ? "Parar Autoplay" : "Iniciar Autoplay"}
            >
              {isAutoplay ? <AiOutlinePauseCircle /> : <AiOutlinePlayCircle />}
              <span className="text-lg">{isAutoplay ? 'Parar Autoplay' : 'Iniciar Autoplay'}</span>
            </button>

            <div className="absolute left-0 top-1/2 transform -translate-y-1/2 z-20 hidden sm:block">
              <button
                className="text-white text-4xl focus:outline-none"
                onClick={(e) => { e.stopPropagation(); showPrev(); }} // Impede o fechamento ao clicar na seta
                aria-label="Imagem Anterior"
              >
                <AiOutlineLeft />
              </button>
            </div>
            <div className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 hidden sm:block">
              <button
                className="text-white text-4xl focus:outline-none"
                onClick={(e) => { e.stopPropagation(); showNext(); }} // Impede o fechamento ao clicar na seta
                aria-label="Próxima Imagem"
              >
                <AiOutlineRight />
              </button>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Gallery;