import React from 'react';

const PrivacidadeContent: React.FC = () => (
  <div>
    <h1>Política de Privacidade</h1>

    <h2>- Sobre a DHeroCorp</h2>
    <p>
      A DHeroCorp é uma empresa especializada na venda de produtos e serviços, com foco em design gráfico, 
      design digital e edição de fotos. Nossa missão é fornecer soluções criativas e de alta qualidade 
      para nossos clientes, atendendo às suas necessidades específicas com excelência.
    </p>

    <h2>- Informações Coletadas</h2>
    <p>
      Coletamos os seguintes dados pessoais dos usuários:
      <ul>
        <li className='font-semibold mb-4'>· Nome completo</li>
        <li className='font-semibold mb-4'>· Endereço de e-mail</li>
        <li className='font-semibold mb-4'>· Número de telefone</li>
      </ul>
    </p>
    <h3>Informações de Menores de Idade</h3>
    <p>
      Coletamos dados apenas de indivíduos com idade superior a 14 anos. Para menores de idade, é necessário 
      obter o consentimento explícito dos pais ou responsáveis legais, que devem assinar o contrato de consentimento.
    </p>

    <h2>- Uso das Informações</h2>
    <p>
      Os dados pessoais coletados são utilizados para as seguintes finalidades:
      <ul>
        <li><strong>Comunicação:</strong> Utilizamos os dados para entrar em contato com os clientes, responder a consultas, fornecer suporte e enviar atualizações sobre nossos serviços.</li>
        <li><strong>Melhoria de Serviços:</strong> Analisamos os dados para entender melhor as necessidades dos clientes e aprimorar nossos produtos e serviços.</li>
        <li><strong>Segurança e Conformidade:</strong> Armazenamos as mensagens dos usuários para garantir a segurança das comunicações e para possíveis necessidades jurídicas, assegurando a conformidade com as regulamentações aplicáveis.</li>
      </ul>
    </p>

    <h2>- Compartilhamento de Informações</h2>
    <p>
      Não compartilhamos os dados pessoais dos usuários com terceiros. O acesso às informações é restrito a pessoal autorizado, 
      garantindo a confidencialidade e a integridade dos dados.
    </p>

    <h2>- Armazenamento e Segurança</h2>
    <p>
      Os dados pessoais são armazenados em um banco de dados em nuvem, utilizando criptografia avançada para proteger as informações 
      contra acessos não autorizados. Implementamos medidas de segurança rigorosas, incluindo controle de acesso e monitoramento contínuo, 
      para garantir a proteção dos dados.
    </p>

    <h2>- Direitos dos Usuários</h2>
    <p>
      Os usuários têm os seguintes direitos em relação aos seus dados pessoais:
      <ul>
        <li><strong>Acesso:</strong> Os usuários podem solicitar acesso aos seus dados pessoais armazenados.</li>
        <li><strong>Correção:</strong> Os usuários podem solicitar a correção de dados incorretos ou desatualizados.</li>
        <li><strong>Alteração:</strong> Os usuários podem alterar seus dados pessoais conforme necessário.</li>
        <li><strong>Revogação de Consentimento:</strong> Os usuários podem revogar seu consentimento para o tratamento de dados a qualquer momento, entrando em contato conosco.</li>
      </ul>
    </p>

    <p><br /><strong>Nota:</strong> Os dados pessoais não poderão ser excluídos uma vez inseridos no sistema, exceto conforme exigido por lei.</p>

    <h2>- Cookies e Tecnologias de Rastreamento</h2>
    <p>
      Atualmente, não utilizamos cookies ou outras tecnologias de rastreamento em nossos serviços.
    </p>

    <h2>- Alterações na Política de Privacidade</h2>
    <p>
      Qualquer alteração na Política de Privacidade será comunicada aos usuários por meio de e-mail, garantindo a transparência 
      e a conformidade com as regulamentações aplicáveis.
    </p>

    <h2>- Contato</h2>
    <p>
      Os usuários podem entrar em contato conosco através do e-mail <a className='text-blue-700 hover:text-blue-950' href="mailto:suporte@dherocorp.com">suporte@dherocorp.com</a> ou pelo nosso contato no WhatsApp (a ser configurado).
    </p>
  </div>
);

export default PrivacidadeContent;