import React from 'react';

const PropriedadeIntelectualContent: React.FC = () => (
  <div>
    <h1>Política de Propriedade Intelectual</h1>
    <h2>- Introdução</h2>
    <p>
      A DHeroCorp valoriza e protege os direitos de propriedade intelectual relacionados aos seus serviços de design gráfico, 
      design digital e edição de fotos. Esta política descreve como protegemos nossas criações e como os clientes podem utilizar nosso conteúdo.
    </p>

    <h2>- Direitos Autorais</h2>
    <p>
      Todos os conteúdos criados pela DHeroCorp, incluindo, mas não se limitando a, designs gráficos, imagens, textos e outros materiais criativos, 
      são protegidos por direitos autorais. Esses direitos são de propriedade exclusiva da DHeroCorp, salvo disposição em contrário em contrato específico.
    </p>

    <h2>- Uso Permitido</h2>
    <p>
      Os clientes que adquirirem nossos serviços recebem uma licença limitada, não exclusiva e intransferível para usar os conteúdos fornecidos 
      apenas para os fins especificados no contrato. Qualquer uso fora do escopo do contrato requer autorização prévia por escrito da DHeroCorp.
    </p>

    <h2>- Direitos Morais</h2>
    <p>
      Mesmo quando os direitos de uso são transferidos ao cliente, a DHeroCorp mantém os direitos morais sobre a obra. Isso inclui o direito de ser 
      reconhecido como o autor do trabalho e o direito de se opor a qualquer modificação que possa prejudicar a integridade ou reputação da obra.
    </p>

    <h2>- Proibição de Plágio</h2>
    <p>
      É estritamente proibido copiar, reproduzir, distribuir, modificar ou criar obras derivadas dos conteúdos da DHeroCorp sem a autorização expressa 
      por escrito. Qualquer violação desta política pode resultar em ações legais.
    </p>

    <h2>- Marcas Registradas</h2>
    <p>
      As marcas, logotipos e nomes comerciais da DHeroCorp são de propriedade exclusiva da empresa. O uso dessas marcas sem autorização prévia por 
      escrito é proibido e pode resultar em ações legais.
    </p>

    <h2>- Proteção de Segredos Comerciais</h2>
    <p>
      A DHeroCorp adota medidas rigorosas para proteger seus segredos comerciais e informações confidenciais. Os clientes e parceiros que tiverem 
      acesso a essas informações devem manter a confidencialidade e não divulgá-las a terceiros sem autorização.
    </p>

    <h2>- Resolução de Disputas</h2>
    <p>
      Qualquer disputa relacionada à propriedade intelectual será resolvida de acordo com as leis aplicáveis e poderá ser submetida a arbitragem ou 
      mediação, conforme acordado entre as partes.
    </p>

    <h2>- Alterações na Política</h2>
    <p>
      Reservamo-nos o direito de alterar esta política a qualquer momento. Quaisquer alterações serão comunicadas aos clientes por e-mail.
    </p>

    <h2>- Contato</h2>
    <p>
      Para mais informações ou dúvidas sobre nossas políticas de propriedade intelectual, entre em contato conosco pelo e-mail: <a className='text-blue-700 hover:text-blue-950' href="mailto:suporte@dherocorp.com">suporte@dherocorp.com</a>.
    </p>
  </div>
);

export default PropriedadeIntelectualContent;