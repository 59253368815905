import React from 'react';

const DesignGrafico: React.FC = () => (
  <div className="p-8 bg-gray-100 text-gray-900">
    <h2 className="text-3xl font-bold text-center mb-8">Serviços de Design Gráfico</h2>

    {/* Criação de Logos e Identidade Visual */}
    <section className="mb-12">
      <h3 className="text-2xl font-semibold text-blue-600 mb-4">Criação de Logos e Identidade Visual</h3>
      <p className="mb-4">
        Desenvolvimento de logotipos exclusivos e personalizados, que capturam a essência da sua marca. Inclui um manual de identidade visual com diretrizes de uso, cores, tipografia, e variações do logo para garantir consistência em todas as comunicações visuais.
      </p>
      <p><strong>Preço:</strong> A partir de R$ 150</p>
      <p><strong>Prazo:</strong> 7 a 14 dias úteis</p>
      <p><strong>Extras:</strong> Manual de identidade visual incluso, com até 2 revisões gratuitas.</p>
    </section>

    {/* Design de Cartões de Visita */}
    <section className="mb-12">
      <h3 className="text-2xl font-semibold text-blue-600 mb-4">Design de Cartões de Visita</h3>
      <p className="mb-4">
        Criação de cartões de visita profissionais, adaptados à identidade visual da marca. Formato personalizável, com design otimizado para impressão frente e verso.
      </p>
      <p><strong>Preço:</strong> A partir de R$ 50</p>
      <p><strong>Prazo:</strong> 3 a 5 dias úteis</p>
      <p><strong>Extras:</strong> Arquivos prontos para impressão.</p>
    </section>

    {/* Design de Flyers e Panfletos */}
    <section className="mb-12">
      <h3 className="text-2xl font-semibold text-blue-600 mb-4">Design de Flyers e Panfletos</h3>
      <p className="mb-4">
        Criação de flyers e panfletos atrativos e funcionais, com foco em comunicação visual eficiente. Ideal para eventos, promoções, ou divulgação de produtos e serviços, com arquivos prontos para impressão em alta resolução.
      </p>
      <p><strong>Preço:</strong> A partir de R$ 50</p>
      <p><strong>Prazo:</strong> 3 a 15 dias úteis</p>
      <p><strong>Extras:</strong> Revisões inclusas até a aprovação final.</p>
    </section>

    {/* Design de Capas de Livros e eBooks */}
    <section className="mb-12">
      <h3 className="text-2xl font-semibold text-blue-600 mb-4">Design de Capas de Livros e eBooks</h3>
      <p className="mb-4">
        Criação de capas de livros e eBooks originais, adaptadas para plataformas de publicação. O design inclui capa frontal, traseira e lombada (para livros físicos), com foco em atratividade e legibilidade.
      </p>
      <p><strong>Preço:</strong> A partir de R$ 50</p>
      <p><strong>Prazo:</strong> 5 a 20 dias úteis</p>
      <p><strong>Extras:</strong> Revisões incluídas até a aprovação final.</p>
    </section>

    {/* Outros Serviços */}
    {/* Inclua seções adicionais para os serviços restantes com o mesmo estilo */}
  </div>
);

export default DesignGrafico;
