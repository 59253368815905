import React, { useState, useEffect } from 'react';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

const unknownImage = "/Site/Unknow.png"; // Certifique-se de que o caminho está correto

interface Testimonial {
  id: number;
  text: string;
  author: string;
  authorImage?: string; // Propriedade opcional para a imagem do autor
}

const testimonials: Testimonial[] = [
  {
    id: 1,
    text: "Trabalhar com Ariel e Victor foi uma experiência fantástica! Ariel traz uma criatividade impressionante para a mesa, sempre conseguindo capturar a essência do que buscamos. Victor, com suas habilidades técnicas excepcionais, complementa perfeitamente essa visão, transformando ideias em soluções práticas e impactantes. Juntos, eles elevam o padrão de qualquer projeto, e nós não poderíamos estar mais satisfeitos com os resultados. Altamente recomendados!",
    author: "Equipe DHeroCorp",
    authorImage: "Site/Logo.png", // Caminho para a logo no Firebase
  },
];

const TestimonialSlider: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [defaultImage, setDefaultImage] = useState(unknownImage);
  const [authorImage, setAuthorImage] = useState<string | null>(null); // Novo estado para a imagem do autor

  useEffect(() => {
    const storage = getStorage();
    const unknownImageRef = ref(storage, 'Site/Unknow.png');

    // Obter a URL da imagem desconhecida
    getDownloadURL(unknownImageRef)
      .then((url) => {
        setDefaultImage(url);
      })
      .catch((error) => {
        console.error("Erro ao buscar a imagem padrão: ", error);
      });

    // Obter a URL da imagem do autor (logo)
    const authorImageRef = ref(storage, 'Site/Logo.png');

    getDownloadURL(authorImageRef)
      .then((url) => {
        setAuthorImage(url); // Define a URL da imagem do autor
      })
      .catch((error) => {
        console.error("Erro ao buscar a imagem do autor: ", error);
      });
  }, []);

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  return (
    <section className="bg-[#040404] text-white my-20">
      <div className="relative flex items-center justify-between w-full max-w-screen-xl mx-auto p-6">
        
        <button className="text-5xl text-white absolute left-0 ml-4" onClick={prevTestimonial}>
          {'<'}
        </button>

        <div className="bg-transparent w-full max-w-2xl p-6 text-center rounded-lg mx-auto flex flex-col justify-between">
          <p className="text-lg mb-5 italic text-center">
            "{testimonials[currentIndex].text}"
          </p>

          <div className="flex flex-col items-center mt-auto">
            <img
              src={authorImage || defaultImage} // Usa a imagem do autor ou a imagem padrão
              alt={testimonials[currentIndex].author}
              className="h-14 w-14 rounded-full"
              onDragStart={(e) => e.preventDefault()}
            />
            <p className="mt-2">{testimonials[currentIndex].author}</p>
          </div>
        </div>

        <button className="text-5xl text-white absolute right-0 mr-4" onClick={nextTestimonial}>
          {'>'}
        </button>
      </div>
    </section>
  );
};

export default TestimonialSlider;