import React from 'react';

const Maintenance: React.FC = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-950">
      <div className="text-center">
        <h1 className="text-4xl font-bold mb-4 text-white">Estamos em Manutenção</h1>
        <p className="text-lg text-gray-500">
          Desculpe pelo transtorno. Estamos trabalhando para melhorar nosso site. Por favor, volte mais tarde.
        </p>
      </div>
    </div>
  );
};

export default Maintenance;