import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

const ContactForm: React.FC = () => {
  const form = useRef<HTMLFormElement | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  const [textareaHeight, setTextareaHeight] = useState<number>(60); // altura inicial do textarea

  const sendEmail = (e: React.FormEvent) => {
    e.preventDefault();

    setSending(true);
    setSuccess(false);
    setError(false);

    if (form.current) {
      emailjs
        .sendForm('service_t274t6a', 'template_8fgdm8v', form.current, 'OY6u3iC-wOtEB8gKv')
        .then(
          () => {
            setSuccess(true);
            setError(false);
            setSending(false);

            setTimeout(() => {
              setSuccess(false);
            }, 3000);

            if (form.current !== null) {
              form.current.reset();
              setTextareaHeight(60); // redefine a altura do textarea
            }
          },
          (error: { text: string }) => {
            setError(true);
            setSuccess(false);
            setSending(false);
          },
        );
    } else {
      console.error('Formulário não encontrado');
      setSending(false);
    }
  };

  // Funções para controlar a altura do textarea
  const handleFocus = () => {
    setTextareaHeight(100); // altura ao focar
  };

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    if (!e.target.value) {
      setTextareaHeight(60); // volta à altura original se não houver texto
    }
  };

  return (
    <div className='flex flex-col md:flex-row justify-between items-center'>
      <section className='flex flex-col justify-center items-center pl-0 md:pl-20 mb-10 md:mb-0 text-center'>
        <h2 className="text-5xl md:text-7xl font-bold text-white mt-6 mb-2 md:mb-0">Nos --------<br/>---- Contate</h2>
        <p className='pt-2 text-white'>Mais dúvidas sobre os serviços? Entre em contato conosco!</p>
      </section>
      <section className="bg-transparent text-center w-full md:w-3/6 mx-0 md:mx-20 my-5 md:my-10 p-10">
        <form ref={form} onSubmit={sendEmail} className="mt-4 max-w-lg mx-auto">
          <div className="relative mb-4">
            <i className="bi bi-person-fill absolute left-3 top-1/2 transform -translate-y-1/2 text-white"></i>
            <input 
              type="text" 
              name="from_name"
              placeholder="Seu Nome"
              className="pl-10 w-full p-3 bg-transparent border-b-2 border-white focus:outline-none text-white" 
              required
            />
          </div>

          <div className="relative mb-4">
            <i className="bi bi-envelope-fill absolute left-3 top-1/2 transform -translate-y-1/2 text-white"></i>
            <input 
              type="email" 
              name="reply_to"
              placeholder="Seu Email"
              className="pl-10 w-full p-3 bg-transparent border-b-2 border-white focus:outline-none text-white" 
              required
            />
          </div>

          <div className="relative mb-4">
            <i className="bi bi-chat-text-fill absolute left-3 top-1/2 transform -translate-y-1/2 text-white"></i>
            <textarea 
              name="message"
              placeholder="Mensagem" 
              className="pl-10 w-full p-3 bg-transparent border-b-2 border-white focus:outline-none text-white resize-none transition-all duration-500" 
              style={{ height: `${textareaHeight}px`, outline: 'none' }}
              onFocus={handleFocus}
              onBlur={handleBlur}
              required
            />
          </div>

          <button type="submit" className="px-6 py-2 w-full bg-purple-600 text-white rounded-lg border-2 border-transparent hover:bg-transparent hover:border-white hover:border-2" style={{ boxSizing: 'border-box' }}>
            Enviar
          </button>
        </form>

        {sending && <p className="text-yellow-500 mt-2">Enviando email...</p>}
        {success && <p className="text-green-500 mt-2">Email enviado com sucesso!</p>}
        {error && <p className="text-red-500 mt-2">Falha ao enviar email. Tente novamente.</p>}
      </section>
    </div>
  );
};

export default ContactForm;
