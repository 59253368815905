import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/TermosStyles.css';
import Footer from '../components/Headers/Footer';
import PrivacidadeContent from '../components/Contents/Privacidade';
import ReembolsoContent from '../components/Contents/Rembolso';
import PropriedadeIntelectualContent from '../components/Contents/Intelectual';
import FAQ from '../components/Contents/FAQ';

const Termos: React.FC = () => {
  const navigate = useNavigate();
  const [selectedSection, setSelectedSection] = useState('FAQ');

  // UseEffect para verificar o hash da URL
  useEffect(() => {
    window.scrollTo(0, 0);

    const hash = window.location.hash;
    if (hash) {
      const section = hash.replace('#', ''); // Remove o '#' do hash
      if (['FAQ', 'Privacidade', 'Reembolso', 'PropriedadeIntelectual'].includes(section)) {
        setSelectedSection(section);
      }
    }
  }, []);

  const renderContent = () => {
    switch (selectedSection) {
      case 'FAQ':
        return <FAQ />;
      case 'Privacidade':
        return <PrivacidadeContent />;
      case 'Reembolso':
        return <ReembolsoContent />;
      case 'Propriedade Intelectual':
        return <PropriedadeIntelectualContent />;
      default:
        return null;
    }
  };

  return (
    <div className="App">
      <button 
        className="return-button mb-4 md:mb-0"
        onClick={() => navigate('/')}
      >
        <i className="bi bi-arrow-left"></i>
      </button>
      <main className="flex flex-col items-center min-h-screen">
        <div className="term-section max-w-screen-lg w-full p-4">
          <h1 className='text-white text-3xl text-center mt-4'>Termos e Condições</h1>
          <section className='w-full bg-white rounded-lg p-4'>
            <nav className='flex flex-col md:flex-row gap-4 md:gap-10 mb-6'>
              <ul 
                className={`cursor-pointer nav-item ${selectedSection === 'FAQ' ? 'selected' : ''}`} 
                onClick={() => setSelectedSection('FAQ')}
                id='FAQ'
              >
                FAQ
              </ul>
              <ul 
                className={`cursor-pointer nav-item ${selectedSection === 'Privacidade' ? 'selected' : ''}`} 
                onClick={() => setSelectedSection('Privacidade')}
                id='Privacidade'
              >
                Privacidade
              </ul>
              <ul 
                className={`cursor-pointer nav-item ${selectedSection === 'Reembolso' ? 'selected' : ''}`} 
                onClick={() => setSelectedSection('Reembolso')}
                id='Reembolso'
              >
                Reembolso e Cancelamento
              </ul>
              <ul 
                className={`cursor-pointer nav-item ${selectedSection === 'Propriedade Intelectual' ? 'selected' : ''}`} 
                onClick={() => setSelectedSection('PropriedadeIntelectual')}
                id='PropriedadeIntelectual'
              >
                Propriedade Intelectual
              </ul>
            </nav>
            <div className='section-content mx-4'>
              {renderContent()}
            </div>
          </section>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Termos;
