import React from 'react';

const EdicaoFotos: React.FC = () => (
  <div className="p-8 bg-gray-100 text-gray-900">
    <h2 className="text-3xl font-bold text-center mb-8">Serviços de Edição de Fotos</h2>

    {/* Edição Básica */}
    <section className="mb-12">
      <h3 className="text-2xl font-semibold text-blue-600 mb-4">Edição Básica</h3>
      <p className="mb-4">
        <strong>Serviços Incluídos:</strong> Brilho e contraste, correção de cor e saturação, recorte e redimensionamento, ajustes de nitidez, redução de olhos vermelhos, remoção de pequenas imperfeições, aplicação de filtros básicos.
      </p>
      <p><strong>Orçamento:</strong> R$ 10 por foto</p>
      <p><strong>Pacote Básico:</strong></p>
      <ul className="list-disc list-inside mb-4">
        <li>5% de desconto = Entre 20 e 50 fotos</li>
        <li>10% de desconto = Mais de 50 fotos</li>
      </ul>
    </section>

    {/* Edição Avançada */}
    <section className="mb-12">
      <h3 className="text-2xl font-semibold text-blue-600 mb-4">Edição Avançada</h3>
      <p className="mb-4">
        <strong>Serviços Incluídos:</strong> Correção de cores avançadas, retoques detalhados, correção de distorções e ajustes de perspectivas, clonagem ou reparações, aplicação de efeitos avançados.
      </p>
      <p><strong>Orçamento:</strong> R$ 20 por foto</p>
      <p><strong>Pacote Avançado:</strong></p>
      <ul className="list-disc list-inside mb-4">
        <li>5% de desconto = Entre 5 e 10 fotos</li>
        <li>10% de desconto = Mais de 10 fotos</li>
      </ul>
    </section>

    {/* Manipulação Criativa */}
    <section className="mb-12">
      <h3 className="text-2xl font-semibold text-blue-600 mb-4">Manipulação Criativa</h3>
      <p className="mb-4">
        <strong>Serviços Incluídos:</strong> Mudança de fundo, combinação de várias imagens, criação de montagens complexas, correção de detalhes avançados e adição de elementos gráficos.
      </p>
      <p><strong>Orçamento:</strong> R$ 50 - R$ 200 por foto, dependendo da complexidade</p>
      <p><strong>Pacote Deluxe:</strong></p>
      <ul className="list-disc list-inside mb-4">
        <li>10% de desconto = Acima de 3 fotos</li>
      </ul>
    </section>

    {/* Cobrança */}
    <section>
      <h3 className="text-2xl font-semibold text-blue-600 mb-4">Cobrança</h3>
      <p><strong>Etapas:</strong></p>
      <ul className="list-disc list-inside">
        <li>Etapa 1 - Conceituação e Inicialização (25%)</li>
        <li>Etapa 2 - Revisão e Amostra (25%)</li>
        <li>Etapa 3 - Finalização e Entrega (50%)</li>
      </ul>
    </section>
  </div>
);

export default EdicaoFotos;
