import React from 'react';

const DesignDigital: React.FC = () => (
  <div className="p-8 bg-gray-100 text-gray-900">
    <h2 className="text-3xl font-bold text-center mb-8">Serviços de Design Digital</h2>

    {/* Design de Layouts de Sites e Blogs */}
    <section className="mb-12">
      <h3 className="text-2xl font-semibold text-blue-600 mb-4">Design de Layouts de Sites e Blogs</h3>
      <p className="mb-4">
        Desenvolvimento de layouts modernos e responsivos para sites e blogs, com foco em usabilidade e estética. Designs otimizados para proporcionar uma experiência de navegação agradável em dispositivos móveis e desktops.
      </p>
      <p><strong>Preço:</strong> A partir de R$ 500</p>
      <p><strong>Prazo:</strong> 10 a 20 dias úteis</p>
      <p><strong>Extras:</strong> Ajustes de responsividade incluídos, revisões ilimitadas até aprovação.</p>
    </section>

    {/* Criação de Wallpapers e Backdrops */}
    <section className="mb-12">
      <h3 className="text-2xl font-semibold text-blue-600 mb-4">Criação de Wallpapers e Backdrops</h3>
      <p className="mb-4">
        Design personalizado de wallpapers e backdrops para uso pessoal, empresarial, ou em eventos. Ideal para branding, personalização de dispositivos, ou decoração digital.
      </p>
      <p><strong>Preço:</strong> A partir de R$ 100</p>
      <p><strong>Prazo:</strong> 3 a 7 dias úteis</p>
      <p><strong>Extras:</strong> Arquivos em alta resolução, prontos para múltiplos dispositivos e plataformas.</p>
    </section>

    {/* Design de E-books */}
    <section className="mb-12">
      <h3 className="text-2xl font-semibold text-blue-600 mb-4">Design de E-books</h3>
      <p className="mb-4">
        Criação de layouts interativos e visuais para e-books, com foco em legibilidade e estética. Otimização para publicação digital, incluindo links internos e externos, imagens, e gráficos.
      </p>
      <p><strong>Preço:</strong> A partir de R$ 700</p>
      <p><strong>Prazo:</strong> 7 a 10 dias úteis</p>
      <p><strong>Extras:</strong> Arquivos prontos para plataformas como Kindle e PDF interativo.</p>
    </section>

    {/* Design de Interfaces de Aplicativos */}
    <section className="mb-12">
      <h3 className="text-2xl font-semibold text-blue-600 mb-4">Design de Interfaces de Aplicativos (UI/UX)</h3>
      <p className="mb-4">
        Desenvolvimento de interfaces intuitivas e atrativas para aplicativos móveis e web. O serviço inclui prototipagem e design focado na experiência do usuário (UX), garantindo funcionalidade e estética.
      </p>
      <p><strong>Preço:</strong> A partir de R$ 500</p>
      <p><strong>Prazo:</strong> 10 a 20 dias úteis</p>
      <p><strong>Extras:</strong> Prototipagem e revisão de usabilidade inclusos.</p>
    </section>

    {/* Design de Publicidade Digital para Eventos Online */}
    <section className="mb-12">
      <h3 className="text-2xl font-semibold text-blue-600 mb-4">Design de Publicidade Digital para Eventos Online</h3>
      <p className="mb-4">
        Criação de anúncios e materiais visuais para eventos online, como banners, posts para redes sociais, e layouts de landing pages. Projetos otimizados para diferentes plataformas digitais.
      </p>
      <p><strong>Preço:</strong> A partir de R$ 100</p>
      <p><strong>Prazo:</strong> 5 a 7 dias úteis</p>
      <p><strong>Extras:</strong> Anúncios otimizados para Facebook, Instagram, Google Ads, entre outros.</p>
    </section>

    {/* Criação de Personagens */}
    <section className="mb-12">
      <h3 className="text-2xl font-semibold text-blue-600 mb-4">Criação de Personagens</h3>
      <p className="mb-4">
        Desenvolvimento de personagens personalizados para projetos como jogos, vídeos, quadrinhos ou branding. O design pode ser adaptado a diferentes estilos (realista, cartoon, anime, etc.), de acordo com a necessidade do projeto.
      </p>
      <p><strong>Preço:</strong> A partir de R$ 100</p>
      <p><strong>Prazo:</strong> 7 a 15 dias úteis</p>
      <p><strong>Extras:</strong> Mockups e revisões até a versão final.</p>
    </section>

    {/* Ilustração de Paisagens Digitais */}
    <section className="mb-12">
      <h3 className="text-2xl font-semibold text-blue-600 mb-4">Ilustração de Paisagens Digitais</h3>
      <p className="mb-4">
        Criação de cenários e paisagens digitais, ideais para jogos, animações, ou arte conceitual. Estilos variados, com mockups e revisões incluídos até a aprovação final.
      </p>
      <p><strong>Preço:</strong> A partir de R$ 100</p>
      <p><strong>Prazo:</strong> 7 a 15 dias úteis</p>
      <p><strong>Extras:</strong> Mockups e revisões incluídos até a aprovação final.</p>
    </section>

    {/* Design de Interface para Jogos */}
    <section>
      <h3 className="text-2xl font-semibold text-blue-600 mb-4">Design de Interface para Jogos</h3>
      <p className="mb-4">
        Desenvolvimento de interfaces interativas para jogos, incluindo menus, HUDs (heads-up displays), e sistemas de navegação no jogo. O design é focado na clareza, estética e facilidade de uso para o jogador.
      </p>
      <p><strong>Preço:</strong> A partir de R$ 300</p>
      <p><strong>Prazo:</strong> 7 a 20 dias úteis</p>
      <p><strong>Extras:</strong> Revisões ilimitadas até aprovação final.</p>
    </section>
  </div>
);

export default DesignDigital;
